// IIFE - Immediately Invoked Function Expression
(function (eneclann) {
  // The global $ object is passed as a parameter
  eneclann(window.$, window, document);
}(function($, window, document) { // The $ is now locally scoped 
  // The $ is now locally scoped

  function modalSafariFix() {

        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {

            $('.modal').on('show.bs.modal', function() {

                // Position modal absolute and bump it down to the scrollPosition
                $(this).css({
                    position: 'absolute',
                    marginTop: $(window).scrollTop() + 'px',
                    bottom: 'auto'
                });

                // Position backdrop absolute and make it span the entire page
                // Also dirty, but we need to tap into the backdrop after Boostrap
                // positions it but before transitions finish.

                setTimeout(function() {
                    $('.modal-backdrop').css({
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: Math.max(
                            document.body.scrollHeight, document.documentElement.scrollHeight,
                            document.body.offsetHeight, document.documentElement.offsetHeight,
                            document.body.clientHeight, document.documentElement.clientHeight
                        ) + 'px'
                    });
                }, 0);

            });

        }

    }

   // Loading Typekit fonts
    function initTypekit() {
      'use strict';
      try {
          Typekit.load({
              active: function() {
                  adjustArticles();
                  $('html').addClass('typekit');
              },
              inactive: function() {
                  adjustArticles();
                  $('html').addClass('typekit');
              }
          })
      } catch (e) {}
    }

  function adjustArticles() {
    var article_rows = $('.row:has(article)'),
      articles,
      tallest = 0;

    article_rows.each(function () {
      articles = $(this).find('div:not(.col-md-12) > article');

      articles.css('height', '');

      if ($(window).width() > 767) {
        $.each(articles, function () {
          if ($(this).outerHeight() > tallest) {
            tallest = $(this).outerHeight();
          }
        });

        $.each(articles, function () {
          var button_offset = $(this).outerHeight() - tallest;
          /* if ( !$(this).hasClass('box') ) {
                        $(this).find('ul, p').css({'bottom':button_offset+'px','position':'relative'});
                    } else {*/
         $(this).find('hr, p.comments').css({ 'bottom': button_offset + 'px', 'position': 'relative' });
          /* }*/
        });

        articles.css('height', tallest);
      }

      articles.unbind().bind({
        click: function() {
          $(this).toggleClass('hover');
        },
      });

      tallest = 0;
    });
  }

  function adjustJumbotron() {
    var jumbotron = $('.jumbotron'),
      window_width,
      image,
      image_size,
      spacing,
      time_delay;

    if (jumbotron.length === 1) {
      // If there is a jumbotron

      window_width = $(window).width();
      image_size = 'small'; // default for mobile
      image = new Image(); // Image object
      time_delay = 100; // For revealing other page elements after the jumbotron

      if (jumbotron.css('opacity') === 1) {
        time_delay = 0;
      } // If page has already been revealed (window resize)

      if (window_width > 768) {
        image_size = 'large';
      }

      image.src = jumbotron.attr('data-' + image_size);

      $(image).load(function() {
          jumbotron.css({ 'opacity': '1', 'background-image': 'url(' + image.src + ')' });
          setTimeout(function() {
              // loadOtherPageElements();
          }, time_delay);

      });
    } else {
      // loadOtherPageElements();
    }
  }

  var navigation = {
    main_navigation: $('#main-navigation'),
    navigation_toggle: $('.navbar-toggle'),

    init: function() {
      navigation.navigation_toggle.click(function() {
          navigation.toggleNavigation();
          return false;
      });
      navigation.initTabLinks();
    },
    toggleNavigation: function() {
      navigation.main_navigation.toggleClass('in');
    },
    getUrlVars: function() {
      var vars = [],
        hash;
      var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
      }

      return vars;
    },
    initTabLinks: function() {
      var url = document.location.toString(),
        target = navigation.getUrlVars().tab,
        search = navigation.getUrlVars().search,
        base_url = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1),
        new_queries;

      if (target != null) {
          $('.nav-tabs a[href=#' + target + ']').trigger('click');
          // Open accordion in admin area
          if ($('#admin-accordion').length > 0) {
              $('#' + target).find('> a').trigger('click');
          }
      } else if (search != null) {
          $('.nav-tabs a:first').tab('show');
      }

      // Change hash for page-reload
      $('.nav-tabs a').on('shown.bs.tab', function(e) {
          if (search != null) {
              new_queries = '?search=' + search + '&';
          } else {
              new_queries = '?';
          }
          new_queries += 'tab=' + e.target.hash.substring(1);
          window.history.pushState({
              "html": $('html').html(),
              "pageTitle": $(document).find("title").text()
          }, "", new_queries);
          adjustArticles();
      });
    },
  };

  // AJAX Actions - Registration, Follow / Unfollow etc
  var ajaxActions = {
    token: $('meta[name="csrf-token"]').attr('content'),

    init: function(settings) {
      // https://learn.$.com/code-organization/concepts/

      // TODO - handle these when not on page
      ajaxActions.bindAllFollowButtons();
      ajaxActions.bindCountyLoader();
      ajaxActions.bindPasswordLoader();
      ajaxActions.initUserSettingsUpdate();
      ajaxActions.initRegistrationForm();
      ajaxActions.initSignedUrls(); // TODO - only if on relevant pages

      // Commission Form
      ajaxActions.initCommissionForm(1);
    },
    initSignedUrls: function() {
      var signed_links = $('a.signed'),
        $self;

      signed_links.bind({
        click: function() {
          $self = $(this);

          // STOP BROWSER FROM BLOCKING WINDOW OPEN WITH POPUP NOTIFCATION
          var win = window.open('');
          window.oldOpen = window.open;
          window.open = function (url) {
            // reassignment function
            win.location = url;
            window.open = oldOpen;
            win.focus();
          };

          $.get($(this).attr('href')).done(function(data) {
            if (data != '') {
              if ($self.hasClass('download')) {
                window.open(data, '_blank');
              } else {
                /*
                                 Open PDF in new page.
                                 Page mode is set to bookmarks but will default back to thumbs if not present in PDF
                                 */
                window.open("https://irishfamilyhistorycentre.com/js/web/viewer.html?file=" + data + "#pagemode=bookmarks", "newPage");
              }
            } else {
              $self.text('NO FILE FOUND!').addClass('btn-danger');
            }
          });

          return false;
        },
      });
    },
    initRegistrationForm: function() {

      var registration_form = $('#registration-form-1'),
        form_data = {
          request_type: 'ajax',
        };

      registration_form.bind({
        submit: function() {
          $(this)
            .find('input, textarea')
            .not(':input[type=submit]')
            .not(':input[type=checkbox]')
            .each(function () {
              form_data[$(this).attr('name')] = $(this).val();
            });

          form_data.terms = $(this).find('input[type=checkbox]').is(':checked') == true ? 1 : 0;

          $.post('/join', form_data)
            .done(function(data) {
              ajaxActions.showRegistrationFormSectionCounties();
            })
            .fail(function(data) {
              var result = $.parseJSON(data.responseText),
                error_keys = Object.keys(result.errors),
                error_messages = [];

              /* TODO - figure out why this would not work with just this:
                                ALSO!! - this is used below in initUserSettingsUpdate
                                $.each(result.errors, function (key, value) {
                                    //console.log(result.errors[key] + ' ' + result.errors[value]);
                                }); */

              $.each(result.errors, function(value) {
                error_messages.push(result.errors[value]);
              });

              $('.help-block').remove();
              $('.form-group').removeClass('has-error');

              for (var i = error_keys.length - 1; i >= 0; i--) {

                  $('input[name=' + error_keys[i] + ']').closest('.form-group').addClass('has-error').append(
                      '<span id="help' + error_keys[i] + '" class="help-block">' + error_messages[i] + '</span>');

              };

            });

          return false;
        },
      });
    },
    showRegistrationFormSectionCounties: function() {
      var next;

      $.get('counties-list').done(function(data) {

          ajaxActions.transitionSteppedForm(function() {

            ajaxActions.bindFollowButtons('county');

            next = $('#next');

            next.bind({
              click: function() {
                ajaxActions.showRegistrationFormSectionTopicsPeople();

                return false;
              },
            });
          }, data, 2 );
        })
        .fail(function(data) { });

    },
    showRegistrationFormSectionTopicsPeople: function() {
      
      var next, back;

      $.get('topics-people').done(function(data) {
        
        ajaxActions.transitionSteppedForm(function() {

            next = $('#next');
            back = $('#back');

            ajaxActions.bindFollowButtons('topic');
            ajaxActions.bindFollowButtons('user');

            next.bind({
              click: function() {
                ajaxActions.showRegistrationFormSectionUserDetails();

                return false;
              },
            });

            back.bind({
              click: function() {
                ajaxActions.showRegistrationFormSectionCounties();
              },
            });
          }, data, 3);
      });

    },
    showRegistrationFormSectionUserDetails: function() {
      var registration_form_final,
        back;

      $.get('user-details').done(function(data) {
      
        ajaxActions.transitionSteppedForm(function() {

            ajaxActions.bindCountyLoader();

            registration_form_final = $('#registration-form-4');
            back = $('#back');

            registration_form_final.bind({
              submit: function() {
                var form_data = {
                  request_type: 'ajax',
                  _token: ajaxActions.token,
                };

                $(this).find('input, textarea, select').not(':input[type=submit]').each(function () {
                  form_data[$(this).attr('name')] = $(this).val();
                });

                $.post('/user-update-on-registration', form_data)
                  .done(function(data) {
                    ajaxActions.showRegistrationFormThanks();
                  })
                  .fail(function(data) { });

                return false;
              },
            });

            back.bind({
              click: function() {
                ajaxActions.showRegistrationFormSectionTopicsPeople();
              },
            });
          }, data, 4);

      });
    },
    transitionSteppedForm: function(callback, data, step) {
      var step_container = $('#step-container'),
        height = step_container.height,
        steps = $('#process-steps li');

      // TODO - make this perfect!
      step_container.css('height', height).animate({ 'opacity': 0 }, 400, function() {
        step_container.css('height', '');
        step_container.html(data);
        step_container.animate({ 'opacity': 1 }, 400);
        adjustArticles();
        callback();
      });

      $("html, body").animate({ scrollTop: $("h1").offset().top }, 400);

      steps.each(function () {
        $(this).removeClass('highlighted').removeClass('active');
        if ($(this).data('step') <= step) {
          $(this).addClass('highlighted');
        }
        if ($(this).data('step') === step) {
          $(this).addClass('active');
        }
      });
    },
    showRegistrationFormThanks: function() {
      $.get('registration-thanks').done(function(data) {
        if (data == 'monthly' || data == 'monthly-coupon' || data == 'annual-new') {
          window.location.replace("/subscribe/?frequency=" + data + "#subscribe");
        } else {
          ajaxActions.transitionSteppedForm(function() {
            }, data, 4);
            setTimeout(function() {
                if (data.indexOf('shopping') == -1) {
                    window.location.replace("/explore");
                }
            }, 5000);
        }
      });
    },
    bindAllFollowButtons: function() {
      ajaxActions.bindFollowButtons('county');
      ajaxActions.bindFollowButtons('topic');
      ajaxActions.bindFollowButtons('user');
      ajaxActions.bindFollowButtons('collection');
    },
    bindFollowButtons: function(object_type) {
      var follow_buttons = $('.select-' + object_type + ' a.btn:not(.view)');

      follow_buttons.each(function () {
        $(this).unbind().bind({
          click: function() {
            var post_url = ($(this).hasClass('checked') == false) ? "/follow/" + object_type : "/unfollow/" + object_type;
            form_data = {
                'id': $(this).data(object_type),
                "_token": ajaxActions.token
            }
            $self = $(this);

            $.post(post_url, form_data).done(function(data) {
              if ($self.hasClass('checked') === true) {
                $self.removeClass('checked');
                $self.text($self.data('followtext'));
                $self.addClass('btn-default');
                $self.removeClass('btn-primary');
                /* if ($self.data('unfollowtext') == 'OK') {
                                    $self.closest('.box.image').parent().remove();
                                }*/
              } else {
                $self.addClass('checked');
                $self.text($self.data('unfollowtext'));
                $self.addClass('btn-primary');
                $self.removeClass('btn-default');
              }
            });

            return false;
          },
        });
      });
    },
    bindSubscriptionCouponLoader: function() {
      var country_select = $('select#country'),
        country_id = parseInt(country_select.val(), 10);

      ajaxActions.loadCounties(country_id);

      country_select.unbind().bind({
        change: function() {
          country_id = parseInt($(this).val(), 10);

          ajaxActions.loadCounties(country_id);
        },
      });
    },
    bindCountyLoader: function() {
      var country_select = $('select#country'),
        country_id = parseInt(country_select.val(), 10);

      ajaxActions.loadCounties(country_id);

      country_select.unbind().bind({
        change: function() {
          country_id = parseInt($(this).val(), 10);

          ajaxActions.loadCounties(country_id);
        },
      });
    },
    loadCounties: function(country_id) {
      var arr = new Array(85, 194, 195, 10),
        county_holder = $('#county-holder');

      if ($.inArray(country_id, arr) != -1) {
        $.get('/county-state/' + country_id).done(function(data) {
          county_holder.html(data).slideDown();
        });
      } else {
        county_holder.slideUp(function() {
          county_holder.html('');
        });
      }
    },
    bindPasswordLoader: function() {
      var change_password = $('a#change_password'),
        password_holder = $('#password-holder');

      change_password.unbind().bind({
        click: function() {
          $.get('/password-form-section').done(function(data) {
            password_holder.html(data).slideDown();
            ajaxActions.bindPasswordUnloader();
            ajaxActions.initUserSettingsUpdate();
          });

          return false;
        },
      });
    },
    bindPasswordUnloader: function() {
      var unchange_password = $('a#unchange_password'),
        password_holder = $('#password-holder');

      unchange_password.unbind().bind({
        click: function() {
          
          password_holder.html('<a href="#" id="change_password" class="btn btn-default">Change Password</a>').slideDown();

          ajaxActions.bindPasswordLoader();

          return false;
        },
      });
    },
    initUserSettingsUpdate: function() {
      var user_settings_update_forms = $('#user-update-forms form'),
        user_settings_update_forms_inputs = user_settings_update_forms.find('input, textarea'),
        user_settings_update_forms_selects = user_settings_update_forms.find('select'),
        user_settings_update_forms_checkboxes = user_settings_update_forms.find('.switch'),
        val_old,
        form_data = { 'request_type': 'ajax', "_token": ajaxActions.token };

      user_settings_update_forms_checkboxes.click(function () {
        var checkbox = $(this).parent().find('input[type=checkbox]'),
          form = $(this).closest('form');

        $(this).find('input[type=checkbox]').each(function () {
          form_data[$(this).attr('name')] = $(this).is(':checked') == true ? 1 : 0;
        });

        // Click doesn't register the one just checked / unchecked so have to do opposite for it
        form_data[checkbox.attr('name')] = checkbox.is(':checked') == true ? 0 : 1;

        form.submit();
      });

      user_settings_update_forms_inputs.unbind().bind({
        mousedown: function() {
          val_old = $(this).val();
        },

        keyup: function() {
          if (val_old != $(this).val()) {
            $(this).parents('form').find('input[type=submit]').val('Save').removeClass('disabled');
          }
        },
      });

      user_settings_update_forms_selects.bind({
        change: function() {
          $(this).parents('form').find('input[type=submit]').val('Save').removeClass('disabled');
        },
      });

      user_settings_update_forms.unbind().bind({
        submit: function() {
          var self = $(this),
            url = $(this).attr('action');

          $(this)
            .find('input, textarea, select')
            .not(':input[type=submit]')
            .not(':input[type=checkbox]')
            .each(function () {
              form_data[$(this).attr('name')] = $(this).val();
            });

          $.post(url, form_data)
            .done(function(data) {
              self.find('input[type=submit]').val('Saved').addClass('disabled');
              self.find('input[type=password]').val('').addClass('disabled');
              $('.help-block').remove();
              $('.form-group').removeClass('has-error');
            })
            .fail(function(data) {
              var status = data.status;

              switch (status) {
                case 400:
                  ajaxActions.addErrorMessages(data);
                  break;
                case 401:
                  window.location.replace('/');
                  break;
                case 405:
                  ajaxActions.displayErrorMessage(self);
                  break;
                case 500:
                  ajaxActions.displayErrorMessage(self);
                  break;
                default:
                  ajaxActions.displayErrorMessage(self);
              }
            });

          return false;
        },
      });
    },
    addErrorMessages: function(data) {
      var result = $.parseJSON(data.responseText),
        error_keys = Object.keys(result.errors),
        error_messages = [];

      $.each(result.errors, function(value) {
        error_messages.push(result.errors[value]);
      });

      $('.help-block').remove();
      $('.form-group').removeClass('has-error');

      for (var i = error_keys.length - 1; i >= 0; i--) {
        $('input[name=' + error_keys[i] + ']').closest('.form-group').addClass('has-error').append(
          '<span id="help' + error_keys[i] + '" class="help-block">' + error_messages[i] + '</span>');
      };

    },
    displayErrorMessage: function(form) {
      form.find('.alert').remove();
      form.append('<div class="alert alert-danger"><p>Sorry but there was an error, please try again later.</p></div>');
    },
    /* Commission Form */
    initCommissionForm: function(step) {
      var commission_form = $('#commission-form-' + step),
                form_data = { 'request_type': 'ajax' },
                post_url = commission_form.attr('action'),
                back = $('#back'),
                file,
                file_selected = false;

      $('input[type=file]').bind({
        change: function(event) {
          file = event.target.files;
          file_selected = true;
        },
      });

      commission_form.unbind().bind({
        submit: function() {
          var data = new FormData();

          data.append('_token', ajaxActions.token);
          data.append('_method', 'PATCH');

          if (file_selected == true) {
            data.append('file', file[0]);
          }

          $(this)
            .find('input, textarea, select')
            .not(':input[type=submit]')
            .not(':input[type=checkbox]')
            .each(function () {
              if (!$(this).is(':radio') || ($(this).is(':radio') && $(this).is(':checked'))) {
                data.append($(this).attr('name'), $(this).val());
              }
            });

          $(this).find('input[type=checkbox]').each(function () {
            data.append($(this).attr('name'), $(this).is(':checked') ? 1 : 0);
          });

          $.ajax({
            url: post_url,
            type: 'POST',
            data: data,
            cache: false,
            dataType: 'json',
            processData: false, // Don't process the files
            contentType: false, // Set content type to false as $ will tell the server its a query string request
            success: function(data, textStatus, jqXHR) {
              ajaxActions.showCommissionFormStep((step + 1), data['commission_id']);
            },
            error: function(jqXHR, textStatus, errorThrown) {
              var result = $.parseJSON(jqXHR['responseText']),
                  error_keys = Object.keys(result.errors),
                  error_messages = [];

              $.each(result.errors, function(value) {
                  error_messages.push(result.errors[value]);
              });

              $('.help-block').remove();
              $('.form-group').removeClass('has-error');

              for (var i = error_keys.length - 1; i >= 0; i--) {
                $('input[name=' + error_keys[i] + '], textarea[name=' + error_keys[i] + ']').closest('.form-group').addClass('has-error').append(
                '<span id="help' + error_keys[i] + '" class="help-block">' + error_messages[i] + '</span>');
              };

            },
          });

          return false;
        },
      });

      back.bind({
        click: function() {
          var url_bits = url.split('/'),
            commission_id = url_bits[4];

          ajaxActions.showCommissionFormStep(step - 1, commission_id);

          return false;
        },
      });
    },
    showCommissionFormStep: function(step, commission_id) {
      $.get('/commission/' + commission_id + '/step/' + step).done(function(data) {

        ajaxActions.transitionSteppedForm(function() {
          ajaxActions.initCommissionForm(step);
        }, data, step);

      })
      .fail(function(data) {

      });
    },
  };

  /** **********************************************************************************************************************/

  /** **********************************************************************************************************************/

  var modalActions = {
    token: $('meta[name="csrf-token"]').attr('content'),

    init: function() {
      modalActions.initModalTriggers();

      if ($('.assign-topics').length == 1) {
        var id = $('.assign-topics').data('id'),
          object = $('.assign-topics').data('object');

        modalActions.bindAssignTopics(object, {
          object_id: id,
        });
      }
    },
    initModalTriggers: function() {
      var modal_toggle = $('.modal-toggle');

      /* $("#modal").on("hidden.bs.modal", function(){
                $(this).find('.modal-body').html('');
            });*/

      if (modal_toggle.length > 0) {
        $('#modal').off().on('show.bs.modal', function (event) {
          $(this).find('.modal-body').html('');
          $(this).find('.modal-dialog').removeClass('trigger');
          var button = $(event.relatedTarget), // Button that triggered the modal
            modal = $(this),
            form = button.data('modal');

          switch (form) {
            case 'instructions':
              modalActions.getQuestionInstructions(modal, button);
              break;
            case 'form':
              modalActions.getQuestionForm(modal, button);
              break;
            case 'new-collection':
              modalActions.getCreateCollectionForm(modal, button, 'new-collection');
              break;
            case 'edit-collection':
              modalActions.getEditCollectionForm(modal, button);
              break;
            case 'delete-collection':
              modalActions.getDeleteCollectionForm(modal, button);
              break;
            case 'new-resource':
              modalActions.getCreateResourceForm({ modal: modal, button: button, step: 1 });
              break;
            case 'add-to-collection':
              modalActions.getAddToCollectionForm(modal, button);
              break;
            case 'add-topic-to-collection':
              modalActions.getAddTopicToResourceForm(modal, button);
              break;
            case 'edit-resource':
              modalActions.getEditResourceForm(modal, button);
              break;
            case 'delete-object':
              modalActions.getDeleteObjectForm(modal, button);
              break;
            case 'edit-profile-photo':
              modalActions.getEditProfilePhotoForm(modal, button);
              break;
            case 'new-resource-for-question-or-commission':
              modalActions.getCreateResourceForQuestionOrCommissionForm(modal, button);
              break;
            case 'registration-trigger':
              modalActions.getPublicationTrigger(modal, button);
              break;
            case 'bio':
              modalActions.getBio(modal, button);
              break;
            case 'publication-preview':
              modalActions.getPublicationPreview(modal, button);
              break;
            case 'update-credit-card':
              modalActions.updateCreditCard(modal, button);
              break;
          }
        });
      }
    },
    getPublicationPreview: function(modal, button) {
      var publication_id = button.data('publication');

      $.get('/publication/' + publication_id + '/preview').done(function(data) {

        modal.find('.modal-body').html(data);

      });

    },

    updateCreditCard: function(modal, button) {
      var user_id = button.data('user');

      $.get('/user/' + user_id + '/credit-card').done(function(data) {
        modal.find('.modal-body').html(data);
      });

      setTimeout(function() {
        StripeUpdateCreditCard.init();
      }, 3000);

    },
    getBio: function(modal, button) {
      var user_id = button.data('user');

      $.get('/user/' + user_id + '/bio').done(function(data) {
        modal.find('.modal-body').html(data);
      });

    },
    getPublicationTrigger: function(modal, button) {
      var publication_id = button.data('publication'),
        trigger = button.data('trigger'),
        url = '/triggers/' + trigger;

      if (publication_id != '') {
        url += '/' + publication_id;
      }

      modal.find('.modal-dialog').addClass('trigger');

      $.get(url).done(function(data) {
        modal.find('.modal-body').html(data);
      });

    },
    highlightErrors: function(data) {
      var result = $.parseJSON(data.responseText),
        error_keys = Object.keys(result.errors),
        error_messages = [];

      $.each(result.errors, function(value) {
        error_messages.push(result.errors[value]);
      });

      $('.help-block').remove();
      $('.form-group').removeClass('has-error');

      for (var i = error_keys.length - 1; i >= 0; i--) {
        $('input[name=' + error_keys[i] + '], textarea[name=' + error_keys[i] + ']').closest('.form-group').addClass('has-error').append(
        '<span id="help' + error_keys[i] + '" class="help-block">' + error_messages[i] + '</span>');
      };

    },
    /* Ask the Expert */
    getQuestionInstructions: function(modal, button) {
      $.get('/question/instructions/').done(function(data) {
        modal.find('.modal-body').html(data);
        modal.find('.modal-dialog').removeClass('narrow');

        var ask_question = $('.ask-question');

        ask_question.bind({
          click: function() {
            modalActions.getQuestionForm(modal, button);
            return false;
          },
        });
      });
    },
    getQuestionForm: function(modal, button) {
      var user = button.data('user');

     $.get('/user/' + user + '/question/create').done(function(data) {
        modal.find('.modal-body').html(data);
        modal.find('.modal-dialog').addClass('narrow');

        modalActions.initAskQuestionForm(modal);
      });
    },
    initAskQuestionForm: function(modal) {
      var ask_question_form = $('#ask-question-form'),
        form_data = { 'request_type': 'ajax' },
        post_url = ask_question_form.attr('action'),
        file,
        file_selected = false;

      $('input[type=file]').bind({
        change: function(event) {
          file = event.target.files;
          file_selected = true;
        },
      });

      ask_question_form.bind({
        submit: function() {
          var data = new FormData();

          data.append('_token', modalActions.token);
          data.append('_method', 'POST');

          if (file_selected == true) {
            data.append('file', file[0]);
          }

          $(this).find('input, textarea, select').not(':input[type=submit]').not(':input[type=checkbox]').each(function() {
            data.append($(this).attr('name'), $(this).val());
          });

          data.append('public', ($(this).find('input[type=checkbox]').is(':checked') == true) ? 1 : 0);

          $.ajax({
            url: post_url,
            type: 'POST',
            data: data,
            cache: false,
            dataType: 'json',
            processData: false, // Don't process the files
            contentType: false, // Set content type to false as $ will tell the server its a query string request
            success: function(data, textStatus, jqXHR) {
              $.get('/question/thanks/').done(function(data) {
                modal.find('.modal-body').html(data);
              });
              setTimeout(function() {
                $('#modal').modal('hide');
                location.reload();
              }, 1000);
            },
            error: function(jqXHR, textStatus, errorThrown) {
              modalActions.highlightErrors(jqXHR);
            },
          });

          return false;
        },
      });
    },
    getCreateResourceForQuestionOrCommissionForm: function(modal, button) {
      $.get('/resource/create-for-question').done(function(data) {
        modal.find('.modal-body').html(data);
        modal.find('.modal-dialog').addClass('narrow');

        modalActions.initCreateResourceForQuestionOrCommissionForm(modal, button);
      });
    },
    initCreateResourceForQuestionOrCommissionForm: function(modal, button) {
      var form = $('#add-resource-form'),
        form_data = { 'request_type': 'ajax' },
        post_url = form.attr('action'),
        file,
        file_selected = false;

      $('input[type=file]').bind({
        change: function(event) {
          file = event.target.files;
          file_selected = true;
        },
      });

      form.unbind().bind({
        submit: function() {
          if (file_selected == true) {

            $('input.btn').attr('value', 'Uploading').prop('disabled', true);

            var data = new FormData();

            data.append('_token', modalActions.token);
            data.append('_method', 'POST');
            data.append('file', file[0]);
            if (button.data('object') == 'question') {
              data.append('question', button.data('id'));
            } else if (button.data('object') == 'commission') {
              data.append('commission', button.data('id'));
            }
            data.append('type', $('input[name=type]').val());

            $.ajax({
              url: post_url,
              type: 'POST',
              data: data,
              cache: false,
              dataType: 'json',
              processData: false, // Don't process the files
              contentType: false, // Set content type to false as $ will tell the server its a query string request
              success: function(data, textStatus, jqXHR) {
                //$(this).find('input[type=submit]').val('Uploading, please wait');
                location.reload();
              },
              error: function(jqXHR, textStatus, errorThrown) {
                var result = $.parseJSON(jqXHR.responseText),
                  error_keys = Object.keys(result.errors),
                  error_messages = [];

                $.each(result.errors, function(value) {
                  error_messages.push(result.errors[value]);
                });

                $('.help-block').remove();
                $('.form-group').removeClass('has-error');

                for (var i = error_keys.length - 1; i >= 0; i--) {
                  
                  $('input[name=' + error_keys[i] + ']').closest('.form-group').addClass('has-error').append(
                  '<span id="help' + error_keys[i] + '" class="help-block">' + error_messages[i] + '</span>');
                  
                  if ( error_keys[i] == 'extension' ) {
                    $('input[name=file]').closest('.form-group').addClass('has-error').append(
                    '<span id="help' + error_keys[i] + '" class="help-block">' + error_messages[i] + '</span>');
                  }

                };

                $('input.btn').attr('value', 'Next').prop('disabled', false);

              }              
            });

          } else {
            $('.help-block').remove();
            $('.form-group').removeClass('has-error');
            $('input[type=file]')
              .closest('.form-group')
              .addClass('has-error')
              .append('<span id="help" class="help-block">Please select a file</span>');
          }

          return false;
        },
      });
    },
    /* Adding objects to collections */
    getAddToCollectionForm: function(modal, button) {
      var user = button.data('user'),
        form_data = {
          object: button.data('object'),
          id: button.data('id'),
        };

      $.get('/user/' + user + '/collection/', form_data).done(function(data) {
        modal.find('.modal-body').html(data);
        modal.find('.modal-dialog').addClass('narrow');

        modalActions.initAddToCollectionForm(modal, button);
      });
    },
    initAddToCollectionForm: function(modal, button) {
      var add_to_collection_form = $('#add-to-collection'),
        form_data = {
          request_type: 'ajax',
        },
        url = add_to_collection_form.attr('action'),
        collections = [],
        new_collection = $('.new-collection');

      new_collection.bind({
        click: function() {
          modalActions.getCreateCollectionForm(modal, button, 'add-object');

          return false;
        },
      });

      add_to_collection_form.bind({
        submit: function() {
          $(this)
            .find('input')
            .not(':input[type=submit]')
            .not(':input[type=checkbox]')
            .each(function () {
              form_data[$(this).attr('name')] = $(this).val();
            });

          $(this).find('input[type=checkbox]').each(function () {
            if ($(this).is(':checked') == true) {
              collections.push($(this).val());
            }
          });

          form_data.collections = collections;

          $.post(url, form_data)
            .done(function(data) {
              modalActions.showObjectAddedMessage(modal, button, collections);
            })
            .fail(function(data) {
              // TODO - handle failure
            });

          return false;
        },
      });
    },
    showObjectAddedMessage: function(modal, button, collections) {
      $.get('/object-added').done(function(data) {
        modal.find('.modal-body').html(data);
        setTimeout(function() {
          $('#modal').modal('hide');
          if (button.data('collection') != '' && button.data('collection') != undefined) {
            window.location = '/user/' + button.data('user') + '/collection/' + button.data('collection');
          } else if (button.data('collection') == undefined) {
            location.reload();
          } else if (collections.length > 0) {
            window.location = '/user/' + button.data('user') + '/collection/' + collections[0];
          } else {
            window.location = /*'/user/' + button.data('user') + */ '/resource/' + button.data('id');
          }
        }, 1000);
      });
    },
    /* Add (to) Collections */
    getCreateCollectionForm: function(modal, button, flow) {
      $.get('/collection/create').done(function(data) {
        modal.find('.modal-body').html(data);
        modal.find('.modal-dialog').addClass('narrow');

        modalActions.initCreateCollectionForm(modal, button, flow);
      });
    },
    initCreateCollectionForm: function(modal, button, flow) {
      var create_collection_form = $('#create-collection'),
        form_data = {
          object: button.data('object'),
          id: button.data('id'),
          request_type: 'ajax',
        };

      create_collection_form.bind({
        submit: function() {
          $(this)
            .find('input, textarea')
            .not(':input[type=submit]')
            .not(':input[type=checkbox]')
            .each(function () {
              form_data[$(this).attr('name')] = $(this).val();
            });

          form_data.public = $(this).find('input[type=checkbox]').is(':checked') == true ? 1 : 0;

          $.post('/user/collection', form_data)
            .done(function(data) {
              if (flow == 'new-collection') {
                setTimeout(function() {
                  $('#modal').modal('hide');
                  window.location = data.url;
                }, 1000);
              } else if (flow == 'add-object') {
                modalActions.getAddToCollectionForm(modal, button);
              }
            })
            .fail(function(data) {
              modalActions.highlightErrors(data);
            });

          return false;
        },
      });
    },
    /* Edit Collection */
    getEditCollectionForm: function(modal, button) {
      var user = button.data('user'),
        collection = button.data('collection');

      $.get('/user/' + user + '/collection/' + collection + '/edit').done(function(data) {
        modal.find('.modal-body').html(data);
        modal.find('.modal-dialog').addClass('narrow');

        modalActions.initEditCollectionForm(modal, button);
      });
    },
    initEditCollectionForm: function(modal, button) {
      var edit_collection_form = $('#edit-collection'),
        form_data = {
          request_type: 'ajax',
        },
        url = edit_collection_form.attr('action');

      edit_collection_form.bind({
        submit: function() {
          $(this)
            .find('input, textarea')
            .not(':input[type=submit]')
            .not(':input[type=checkbox]')
            .each(function () {
              form_data[$(this).attr('name')] = $(this).val();
            });

          form_data.public = $(this).find('input[type=checkbox]').is(':checked') == true ? 1 : 0;

          $.post(url, form_data)
            .done(function(data) {
              setTimeout(function() {
                $('#modal').modal('hide');
                window.location = data.url;
              }, 1000);
            })
            .fail(function(data) {
              modalActions.highlightErrors(data);
            });

          return false;
        },
      });
    },
    /* Edit Collection */
    getDeleteCollectionForm: function(modal, button) {
      var user = button.data('user'),
        collection = button.data('collection');

      $.get('/user/' + user + '/collection/' + collection + '/delete-form').done(function(data) {
        modal.find('.modal-body').html(data);
        modal.find('.modal-dialog').addClass('narrow');
      });
    },
    /* Add New Resource */
    getCreateResourceForm: function(args) {
      var parameters = (args.object_id != null ? '/' + args.object_id : '');

      $.get('/resource/create/step/' + args.step + parameters).done(function(data) {
        args.modal.find('.modal-body').html(data);
        args.modal.find('.modal-dialog').addClass('narrow');

        modalActions.initCreateResourceForm(args);
      });
    },
    initCreateResourceForm: function(args) {
      $('a.cancel').unbind().bind({
        click: function() {
          $('#modal').modal('hide');

          return false;
        },
      });

      if (args.step == 1) {
        $('a.resource-type').unbind().bind({
          click: function() {
            if ($(this).hasClass('pdf')) {
              args.step = '2-pdf';
            } else if ($(this).hasClass('image')) {
              args.step = '2-image';
            } else if ($(this).hasClass('video')) {
              args.step = '2-video';
            } else if ($(this).hasClass('url')) {
              args.step = '2-url';
            }

            modalActions.getCreateResourceForm(args);

            return false;
          },
        });
      } else if ($.inArray(args.step, ['2-video', '2-url', 3, 4]) !== -1) {
        modalActions.bindCreateResourceForm(args);
      } else if ($.inArray(args.step, ['2-pdf', '2-image']) !== -1) {
        modalActions.bindCreateResourceFileForm(args);
      }
    },
    bindCreateResourceFileForm: function(args) {
      var form = $('#add-resource-form'),
        form_data = { 'request_type': 'ajax' },
        post_url = form.attr('action'),
        file,
        file_selected = false;

      args.step = 3;

      $('input[type=file]').bind({
        change: function(event) {
          file = event.target.files;
          file_selected = true;
        },
      });

      form.unbind().bind({
        submit: function() {
          $('input.btn').attr('value', 'Uploading').prop('disabled', true);

          if (file_selected == true) {
            var data = new FormData();

            data.append('_token', modalActions.token);
            data.append('_method', 'POST');
            data.append('file', file[0]);

            var is_public = $(this).find('input[type=checkbox]').is(':checked') == true ? 1 : 0;
            data.append('public', is_public);

            if (args.step == 3) {
              data.append('collection', args.button.data('collection'));
              data.append('type', $('input[name=type]').val());
            }

            $.ajax({
              url: post_url,
              type: 'POST',
              data: data,
              cache: false,
              dataType: 'json',
              processData: false, // Don't process the files
              contentType: false, // Set content type to false as $ will tell the server its a query string request
              success: function(data, textStatus, jqXHR) {
                args.object_id = data.id;
                modalActions.getCreateResourceForm(args);
              },
              error: function(jqXHR, textStatus, errorThrown) {
                var result = $.parseJSON(jqXHR.responseText),
                  error_keys = Object.keys(result.errors),
                  error_messages = [];

                $.each(result.errors, function(value) {
                  error_messages.push(result.errors[value]);
                });

                $('.help-block').remove();
                $('.form-group').removeClass('has-error');

                for (var i = error_keys.length - 1; i >= 0; i--) {

                    $('input[name=' + error_keys[i] + ']').closest('.form-group').addClass('has-error').append(
                        '<span id="help' + error_keys[i] + '" class="help-block">' + error_messages[i] + '</span>');

                };

                $('input.btn').attr('value', 'Next').prop('disabled', false);
              },
            });
          } else {
            $('.help-block').remove();
            $('.form-group').removeClass('has-error');
            $('input[type=file]')
              .closest('.form-group')
              .addClass('has-error')
              .append('<span id="help" class="help-block">Please select a file</span>');
          }

          return false;
        },
      });
    },
    bindCreateResourceForm: function(args) {
      var form = $('#add-resource-form'),
        form_data = { 'request_type': 'ajax' },
        post_url = form.attr('action');

      if (args.step == 3) {
        args.step = 4;
      } else if (args.step == 4) {
        args.step = 5;
      } else {
        args.step = 3;
      }

      form.unbind().bind({
        submit: function() {
          $('input.btn').attr('value', 'Uploading').prop('disabled', true);

          $(this).find('input, textarea').not(':input[type=submit]').each(function () {
            form_data[$(this).attr('name')] = $(this).val();
          });

          if (args.step == 3) {
            form_data.collection = args.button.data('collection');
          }

          $.post(post_url, form_data)
            .done(function(data) {
              args.object_id = data.id;

              modalActions.getCreateResourceForm(args);
            })
            .fail(function(data) {
              var result = $.parseJSON(data.responseText),
                error_keys = Object.keys(result.errors),
                error_messages = [];

              $.each(result.errors, function(value) {
                error_messages.push(result.errors[value]);
              });

              $('.help-block').remove();
              $('.form-group').removeClass('has-error');

              for (var i = error_keys.length - 1; i >= 0; i--) {

                  $('input[name=' + error_keys[i] + ']').closest('.form-group').addClass('has-error').append(
                      '<span id="help' + error_keys[i] + '" class="help-block">' + error_messages[i] + '</span>');

              };

              $('input.btn').attr('value', 'Next').prop('disabled', false);
            });

          return false;
        },
      });

      if (args.step == 5) {
        modalActions.bindAssignTopics('resource', args);

        $('a.next').bind({
          click: function() {
            args.button.data('id', args.object_id);

            if (args.flow == 'add-topic') {
              setTimeout(function() {
                $('#modal').modal('hide');
                location.reload();
              }, 1000);
            } else {
              modalActions.getAddToCollectionForm(args.modal, args.button);
            }

            return false;
          },
        });
      }
    },
    getAddTopicToResourceForm: function(modal, button) {
      modalActions.getCreateResourceForm({
        modal: modal,
        button: button,
        step: 4,
        object_id: button.data('resource'),
        flow: 'add-topic',
      });
    },
    getEditResourceForm: function(modal, button) {
      var user = button.data('user'),
        resource = button.data('resource');

      $.get('/resource/' + resource + '/edit-form').done(function(data) {
        modal.find('.modal-body').html(data);
        modal.find('.modal-dialog').addClass('narrow');

        modalActions.bindEditResourceForm(modal, button);
      });
    },
    bindEditResourceForm: function(modal, button) {
      
      var form = $('#edit-resource-form'),
        form_data = {
          request_type: 'ajax',
        },
        post_url = form.attr('action');

      form.unbind().bind({
        submit: function() {
          $(this).find('input, textarea').not(':input[type=submit]').each(function () {
            form_data[$(this).attr('name')] = $(this).val();
          });

          form_data.public = $(this).find('input[type=checkbox]').is(':checked') == true ? 1 : 0;

          $.post(post_url, form_data)
            .done(function(data) {
              setTimeout(function() {
                $('#modal').modal('hide');
                location.reload();
              }, 1000);
            })
            .fail(function(data) {
              var result = $.parseJSON(data.responseText),
                error_keys = Object.keys(result.errors),
                error_messages = [];

              $.each(result.errors, function(value) {
                error_messages.push(result.errors[value]);
              });

              $('.help-block').remove();
              $('.form-group').removeClass('has-error');

              for (var i = error_keys.length - 1; i >= 0; i--) {

                $('input[name=' + error_keys[i] + '], textarea[name=' + error_keys[i] + ']').closest('.form-group').addClass('has-error').append(
                 '<span id="help' + error_keys[i] + '" class="help-block">' + error_messages[i] + '</span>');
              };

            });

          return false;
        },
      });
    },
    bindAssignTopics: function(object, args) {
      var topics_links = $('.assign-topics a'),
        filter = $('#filter-topics'),
        filter_term,
        remove_filter = $('.remove-filter');

      filter.bind({
        keyup: function() {
          filter_term = $(this).val().toLowerCase();
          modalActions.filterTopics(topics_links, filter_term);
        },
      });

      remove_filter.bind({
        click: function() {
          filter_term = '';
          filter.val('');
          modalActions.filterTopics(topics_links, filter_term);
          return false;
        },
      });

      topics_links.bind({
        click: function() {
          var topic_type = $(this).data('topic-type');

          var post_url = ($(this).hasClass('checked') == false) ? "/" + object + "/" + args.object_id + "/assign-" + topic_type : "/" + object + "/" + args.object_id + "/remove-" + topic_type;

          (form_data = {
            id: $(this).data('topic'),
            _token: modalActions.token,
          }), ($self = $(this));

          $.post(post_url, form_data).done(function(data) {
            if ($self.hasClass('checked') === true) {
              $self.removeClass('checked btn-primary').addClass('btn-default');
            } else {
              $self.removeClass('btn-default').addClass('checked btn-primary');
            }
          });

          return false;
        },
      });
    },
    filterTopics: function(topics_links, filter_term) {
      topics_links.each(function () {
        if ($(this).text().toLowerCase().indexOf(filter_term) >= 0) {
          $(this).addClass('in');
          $(this).fadeIn();
        } else {
          $(this).removeClass('in');
          $(this).fadeOut();
        }
      });

      var rows = $('.assign-topics .row');

      rows.each(function () {
        if ($(this).find('a.in').length > 0) {
          $(this).fadeIn();
        } else {
          $(this).fadeOut();
        }
      });
    },
    /* Delete Object (Resource, Article, Event) */
    getDeleteObjectForm: function(modal, button) {
      var user = button.data('user'),
        object_type = button.data('object'),
        object_id = button.data('id'),
        admin = object_type != 'resource' ? '/admin' : '';

      // TODO - check middleware on these to stop people deleting things they shouldnt

      $.get(admin + '/' + object_type + '/' + object_id + '/delete-form').done(function(data) {
        modal.find('.modal-body').html(data);
        modal.find('.modal-dialog').addClass('narrow');
      });

    },
    getEditProfilePhotoForm: function(modal, button) {
      var user = button.data('user');

      $.get('/user/' + user + '/edit-profile-photo').done(function(data) {
        modal.find('.modal-body').html(data);
        modal.find('.modal-dialog').addClass('narrow');

        modalActions.bindEditProfilePhotoForm(modal, button);
      });
    },
    bindEditProfilePhotoForm: function(modal, button) {
      var form = $('#edit-profile-photo-form'),
        form_data = {
          request_type: 'ajax',
        },
        post_url = form.attr('action'),
        file,
        file_selected = false;

      $('input[type=file]').bind({
        change: function(event) {
          file = event.target.files;
          file_selected = true;
        },
      });

      form.unbind().bind({
        submit: function() {
          if (file_selected == true) {
            var data = new FormData();

            data.append('_token', modalActions.token);
            data.append('_method', 'PATCH');
            data.append('profile_image', file[0]);

            $.ajax({
              url: post_url,
              type: 'POST',
              data: data,
              cache: false,
              dataType: 'json',
              processData: false, // Don't process the files
              contentType: false, // Set content type to false as $ will tell the server its a query string request
              success: function(data, textStatus, jqXHR) {
                location.reload();
              },
              error: function(jqXHR, textStatus, errorThrown) {
                var result = $.parseJSON(jqXHR.responseText),
                  error_keys = Object.keys(result.errors),
                  error_messages = [];

                $.each(result.errors, function(value) {
                  error_messages.push(result.errors[value]);
                });

                $('.help-block').remove();
                $('.form-group').removeClass('has-error');

                for (var i = error_keys.length - 1; i >= 0; i--) {
                  $('input[name=' + error_keys[i] + ']').closest('.form-group').addClass('has-error').append(
                  '<span id="help' + error_keys[i] + '" class="help-block">' + error_messages[i] + '</span>');
                };

              },
            });
          } else {
            $('.help-block').remove();
            $('.form-group').removeClass('has-error');
            $('input[type=file]')
              .closest('.form-group')
              .addClass('has-error')
              .append('<span id="help" class="help-block">Please select a file</span>');
          }

          return false;
        },
      });
    },
  };

  var collectionPrivacy = {
    init: function() {
      var collection_privacy = $('#collection-privacy'),
        collection_checkbox = collection_privacy.find('input[type=checkbox]'),
        form_data = {
          request_type: 'ajax',
        };

      collection_privacy.bind({
        submit: function() {
          $(this).find('input[type=hidden]').each(function () {
            form_data[$(this).attr('name')] = $(this).val();
          });

          form_data.public = $(this).find('input[type=checkbox]').is(':checked') == true ? 1 : 0;

          $.post(collection_privacy.attr('action'), form_data)
            .done(function(data) {
              // Nothing to do really
            })
            .fail(function(data) {
              // Undo action on the checkbox with an error message
              var checkbox = collection_privacy.find('input[type=checkbox]');
              if (checkbox.is(':checked') == true) {
                checkbox.attr('checked', false);
              } else {
                checkbox.attr('checked', true);
              }
              $('.help-block').remove();
              $('input[name=public]')
                .closest('form')
                .addClass('has-error')
                .append(
                  '<span class="help-block">There was an issue updating your settings, please try again later</span>'
                );
            });

          return false;
        },
      });

      collection_checkbox.click(function() {
        collection_privacy.submit();
      });
    },
  };

  function adminFunctions() {
    // For adding / editing articles
    if (typeof CKEDITOR !== 'undefined' && $('textarea[id=content]').length > 0) {
      CKFinder.setupCKEditor();
      CKEDITOR.replace('content', {
        filebrowserBrowseUrl: '/ckfinder/ckfinder.html', // ?resourceType=Images',
        currentFolder: '/images/uploads/', // TODO - check this works for S3
        /*filebrowserUploadUrl: '/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files'*/
      });
    }
    // For adding / editing research
    if (typeof CKEDITOR !== 'undefined' && $('textarea[id=content2]').length > 0) {
      CKFinder.setupCKEditor();
      CKEDITOR.replace('content2', {
        filebrowserBrowseUrl: '/ckfinder/ckfinder.html', // ?resourceType=Images',
        currentFolder: '/images/uploads/',
      });
    }
    // For adding / editing user bios
    if (typeof CKEDITOR !== 'undefined' && $('textarea[id=bio]').length > 0) {
      CKFinder.setupCKEditor();
      CKEDITOR.replace('bio', {
        filebrowserBrowseUrl: '/ckfinder/ckfinder.html', // ?resourceType=Images',
        currentFolder: '/images/uploads/', // TODO - check this works for S3
        /*filebrowserUploadUrl: '/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files'*/
      });
    }
  }

  function bindShowArticleParents() {
    var article_type = $('#article_type_id');

    article_type.bind({
      change: function() {
        updateArticleList($(this));
      },
    });

    updateArticleList(article_type);
  }

  function updateArticleList(article_type) {
    var extra_fields = $('#parent-article, #banner-image');

    if (article_type.val() == 1) {
      extra_fields.slideDown();
    } else {
      extra_fields.slideUp();
    }
  }

  var initFilter = {
    init: function() {
      $('#filter').on('change', function (e) {
        if ( $(this).val() != '' ) {
          this.form.submit();
          }
      });

      $('#filter2').on('change', function (e) {
        this.form.submit();
      });
    },
  };

  var initCheckout = {
    init: function() {
      initCheckout.initApplyCoupon();
      initCheckout.preselectSubscription();
      initCheckout.initUpdateShippingCharges();
      initCheckout.loadSubscriptionCoupon();
    },
    initApplyCoupon: function() {
      $('#apply-coupon').on('submit', function () {
        $('.invalid').hide();
        $('.valid').hide();
        $('.discounted-total').hide();

        $.post(
          $(this).prop('action'),
          {
            _token: $(this).find('input[name=_token]').val(),
            coupon: $('#coupon').val(),
          },
          function(data) {
            // do something with data/response returned by server
            var couponCode = data.coupon_amount;
            if (couponCode != 0) {
              $('.discounted-total').removeClass('hidden');
              var total = $('.total').attr('value');
              var discountedTotal = total - couponCode / 100 * total;
              $('.discounted-total').show();
              $('.discounted-total').append('<span class="discount">' + discountedTotal.toFixed(2) + '</span>');
              $('#apply-coupon-button').addClass('hidden');
              $('#apply-coupon').append("<h3 class='valid'>Discount Applied</h3>");
              $('.invalid').hide();
            } else {
              $('#apply-coupon').append("<h3 class='invalid'>" + data.msg + "</h3>");
            }
          },
          'json'
        );
        // prevent the form from actually submitting in browser
        return false;
      });
    },
    preselectSubscription: function() {
      var subscription = getUrlParameter('frequency');

      $('select#subscription').val(subscription);

      initCheckout.bindSubscriptionCouponLoader();
    },
    bindSubscriptionCouponLoader: function() {
      var subscription_select = $('select#subscription'),
        subscription = subscription_select.val();

      initCheckout.loadSubscriptionCoupon(subscription);

      subscription_select.unbind().bind({
        change: function() {
          subscription = $(this).val();

          initCheckout.loadSubscriptionCoupon(subscription);
        },
      });
    },
    loadSubscriptionCoupon: function(subscription) {
      var coupon_wrapper = $('#coupon-wrapper'),
        coupon_message = $('#coupon-message'),
        submit = $('.submit-button'),
        submit_original_text = submit.prop('value');

      /* if (subscription == 'monthly-coupon') {
                coupon_wrapper.fadeIn();
                coupon_message.fadeIn();
                submit.prop('value', 'Start Free month');
            } else {
                coupon_wrapper.fadeOut(400, function() {
                    coupon_wrapper.find('#coupon').val('');
                });
                coupon_message.fadeOut();
                submit.prop('value', submit_original_text);
            } */

      coupon_wrapper.show();
    },
    initUpdateShippingCharges: function() {
      var shipping_country = $('#purchase-form select#shipping_country'),
        country_id,
        rate_displays = $('.rate-display'),
        total = $('#total').text(),
        shipping,
        grand_total_display = $('.grand-total'),
        grand_total;

      shipping_country.bind({
        change: function() {
          country_id = $(this).val();

          $.get('/shipping-rate/' + country_id).done(function(data) {
            rate_displays.each(function () {
              if (
                ($(this).hasClass('ireland') && country_id == '85') ||
                (!$(this).hasClass('ireland') && country_id != '85')
              ) {
                shipping = $(this).find('#shipping').text();
                // TODO - figure this out for display and checkout price
                if ($('.discounted-total').is(':visible')) {
                  discountedTotal = $('.discount').text();
                  grand_total = parseFloat(shipping) + parseFloat(discountedTotal);
                  grand_total = grand_total.toFixed(2);
                } else {
                  grand_total = parseFloat(total) + parseFloat(shipping);
                  grand_total = grand_total.toFixed(2);
                }
                grand_total_display.find('#grand-total').text(grand_total);
                grand_total_display.removeClass('hidden').fadeIn();
                $(this).fadeIn();
              } else {
                $(this).fadeOut();
              }
            });
          });
        },
      });
    },
  };

  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : sParameterName[1];
      }
    }
  };

  var initStripe = {
    init: function() {
      $.ajaxSetup({
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        },
      });

      initStripe.initPaymentForms();
    },
    initPaymentForms: function() {
      $('#card-number').focusout(function () {
        //console.log('here');
        var el = $(this);
        if (!Stripe.validateCardNumber(el.val())) {
          el.closest('.form-group').addClass('has-error');
        } else {
          el.closest('.form-group').removeClass('has-error');
        }
      });
      $('#card-cvc').focusout(function () {
        var el = $(this);
        if (!Stripe.validateCVC(el.val())) {
          el.closest('div').addClass('has-error');
        } else {
          el.closest('div').removeClass('has-error');
        }
      });
      $('#purchase-form, #subscription-form').submit(function (e) {
        $('.submit-button').prop('disabled', true);
        var $form = $(this);
        $form.find('.payment-errors').hide();
        Stripe.card.createToken(
          {
            number: $form.find('#card-number').val(),
            cvc: $form.find('#card-cvc').val(),
            exp_month: $form.find('#card-month').val(),
            exp_year: $form.find('#card-year').val(),
          },
          initStripe.stripeResponseHandler
        );

        return false;
      });
    },
    stripeResponseHandler: function(status, response) {
      var $form = $('#purchase-form, #subscription-form'),
        $errors = $('.payment-errors'),
        coupon = $('#coupon').val();

      // Reset any errors
      $errors.text('');

      $('.help-block').remove();
      $('.form-group').removeClass('has-error');

      if (response.error) {
        $form.find('[data-stripe="' + response.error.param.replace("_", "-") + '"]').closest(".form-group").addClass("has-error").append(
            '<span id="help" class="help-block">' + response.error.message + '</span>');
        //$errors.text(response.error.message).show();
        $form.find('input.submit-button').prop('disabled', false);
      } else {
        var token = response.id;
        $form.append($('<input type="hidden" name="stripe_token" />').val(token));
        $form.append($('<input type="hidden" name="coupon" />').val(coupon));
        //console.log($(this).closest($form).submit());
        // $(this).closest($form).submit();
        // console.log($form.get(0));
        $form.get(0).submit();
        $(this).closest($form).find('button').html('Processing...');
      }
    },
  };

  // Needed to create an a separate method for updating credit card as one above shares functionality with the Purchase and Subscription Form.
  var StripeUpdateCreditCard = {
    init: function() {
      $.ajaxSetup({
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        },
      });

      StripeUpdateCreditCard.initUpdateForm();
    },

    initUpdateForm: function() {
      $('#card-number').focusout(function () {
        var el = $(this);
        if (!Stripe.validateCardNumber(el.val())) {
          el.closest('.form-group').addClass('has-error');
        } else {
          el.closest('.form-group').removeClass('has-error');
        }
      });
      $('#card-cvc').focusout(function () {
        var el = $(this);
        if (!Stripe.validateCVC(el.val())) {
          el.closest('div').addClass('has-error');
        } else {
          el.closest('div').removeClass('has-error');
        }
      });

      $('#update-credit-card').submit(function (e) {
        var $form = $(this);

        $('.help-block').remove();

        $('.credit-card-button').val('Processing...');

        // Disable the submit button to prevent repeated clicks
        $form.find('button').prop('disabled', true);

        Stripe.card.createToken(
          {
            number: $form.find('#card-number').val(),
            cvc: $form.find('#cvc').val(),
            exp_month: $form.find('#card-month').val(),
            exp_year: $form.find('#card-year').val(),
          },
          function(status, response) {
            var $form = $('#update-credit-card');
            if (response.error) {
              var errorParam = response.error.param.replace('_', '-');
              $('[data-stripe="'+ errorParam + '"]')
                .closest('.form-group')
                .addClass('has-error')
                .append('<span id="help" class="help-block">' + response.error.message + '</span>');

              $('.credit-card-button').text('Update');
            } else {
              // token contains id, last4, and card type
              var token = response.id;
              var cardId = response.card.id;
              var last4 = response.card.last4;
              var expMonth = response.card.exp_month;
              var expYear = response.card.exp_year;
              var cvc = response.card.cvc;

              // Insert the token into the form so it gets submitted to the server
              var data = new FormData();

              data.append('token', token);
              data.append('cardId', cardId);
              data.append('last4', last4);
              data.append('expMonth', expMonth);
              data.append('expYear', expYear);
              data.append('cvc', cvc);

              // and re-submit via AJAX
              $.ajax({
                url: 'credit-card',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                processData: false, // Don't process the files
                contentType: false,
                success: function(data) {
                  //console.log(data);
                  $('#modal').modal('toggle');
                  $('.last4').text(last4);
                },
                error: function(errormessage) {
                  //console.log(data);
                },
              });
            }
          }
        );

        // Prevent the form from submitting with the default action
        return false;
      });
    },

    // return CreditCard;
  };

  /* Upload PDF publications straight to AWS */

  var publicationUpload = {
    publication_id: null,
    publication_download_updated: false,

    uploader: new plupload.Uploader({
      browse_button: 'browse',

      runtimes: 'html5,flash,silverlight',

      url: 'https://' + $('#aws-details').data('bucket') + '.' + $('#aws-details').data('region') + '.amazonaws.com/',

      multipart: true,
      multipart_params: {
        key: 'publications/${filename}', // use filename as a key
        Filename: 'publications/${filename}', // adding this to keep consistency across the runtimes
        acl: 'public-read',
        'Content-Type': 'application/pdf',
        AWSAccessKeyId: $('#aws-details').data('key'),
        policy: $('#aws-details').data('policy'),
        signature: $('#aws-details').data('signature'),
      },

      multi_selection: false,

      max_file_count: 1,

      // optional, but better be specified directly
      file_data_name: 'file',
      filters: {
        // Maximum file size
        max_file_size: '700mb',
        // Specify what files to browse for
        mime_types: [
          {
            title: 'PDFs',
            extensions: 'pdf',
          },
        ],
      },
      // Flash settings
      flash_swf_url: '../../js/plupload-2.1.9/js/Moxie.swf',
      // Silverlight settings
      silverlight_xap_url: '../../js/plupload-2.1.9/js/Moxie.xap',
    }),

    init: function() {
      publicationUpload.uploader.init();

      // Clear list when browse clicked, so only one can be added
      publicationUpload.uploader.bind('Browse', function() {
        plupload.each(publicationUpload.uploader.files, function(file) {
          publicationUpload.uploader.removeFile(file.id);
        });
        setTimeout(function() {
          document.getElementById('filelist').innerHTML = '';
        }, 500);
      });

      publicationUpload.uploader.bind('FilesAdded', function(up, files) {
        var html = '';
        plupload.each(files, function(file) {
          html += '<li id="' + file.id + '"><span class="filename">' + file.name + '</span> (' + plupload.formatSize(file.size) + ') <b></b></li>';
        });
        document.getElementById('filelist').innerHTML += html;
      });

      publicationUpload.uploader.bind('UploadProgress', function(up, file) {
        if (file.percent == 100) {
          if (publicationUpload.publication_download_updated == false) {
            publicationUpload.publication_download_updated = true;
            publicationUpload.updatePublicationInDatabase();
          }
        }
        document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '<span>' + file.percent + "%</span>";
      });

      publicationUpload.uploader.bind('Error', function(up, err) {
        document.getElementById('console').innerHTML += "\nError #" + err.code + ": " + err.message;
      });

      publicationUpload.initForm();
    },

    updatePublicationInDatabase: function() {
      var data = new FormData();

      data.append('_token', $('meta[name="csrf-token"]').attr('content'));
      data.append('_method', 'PATCH');
      data.append('download', $('.filename').text());

      $.ajax({
        url: '/admin/publications/' + publicationUpload.publication_id + '/update-download',
        type: 'POST',
        data: data,
        cache: false,
        dataType: 'json',
        processData: false, // Don't process the files
        contentType: false, // Set content type to false as $ will tell the server its a query string request
        success: function(data, textStatus, jqXHR) {
          publicationUpload.publication_download_updated = true;
          $('input[type=submit]').slideUp(1000);
          if ($('#topic-link').hasClass('noshow')) {
           $('#topic-link').html('<p class="text-center"><a class="btn btn-primary btn-lg" href="/admin/publication/' + publicationUpload.publication_id + '/topics?flow=back-end">Edit Topics</a></p>').slideDown(1000).removeClass('noshow');
          }
        },
        error: function(jqXHR, textStatus, errorThrown) {
          document.getElementById('console').innerHTML +=
            '<br/>There was a problem updating the name of the file in the database!';
        },
      });
    },

    initFileUpload: function(data) {
      publicationUpload.publication_id = data.id;

      if (publicationUpload.uploader.files.length == 1) {
        $('form .form-group, #browse').slideUp(1000);
        $('input[type=submit]').prop('disabled', true).attr('value', 'Uploading');

        publicationUpload.uploader.settings.multipart_params.key = 'publications/publication-' + publicationUpload.publication_id + '-${filename}'; // use filename as a key

        publicationUpload.uploader.start();
      } else {
        window.location.href = '/admin/publication/' + publicationUpload.publication_id + '/topics?flow=' + data.flow;
      }
    },

    initForm: function() {
      var form = $('#add-publication, #edit-publication'),
        /* form_data = {'request_type' : 'ajax'},*/
        post_url = form.attr('action'),
        file,
        file_selected = false;

      $('input[name=image]').bind({
        change: function(event) {
          file = event.target.files;
          file_selected = true;
        },
      });

      form.unbind().bind({
        submit: function() {
          var data = new FormData();

          data.append('_token', $('meta[name="csrf-token"]').attr('content'));

          if (form.attr('id') == 'edit-publication') {
            data.append('_method', 'PATCH');
          }

          if (file_selected == true) {
            data.append('image', file[0]);
          }

          $(this)
            .find('input, textarea, select')
            .not(':input[type=submit]')
            .not(':input[type=file]')
            .each(function () {
              if (!$(this).is(':checkbox') || ($(this).is(':checkbox') && $(this).is(':checked'))) {
                data.append($(this).attr('name'), $(this).val());
              }
            });

          $.ajax({
            url: post_url,
            type: 'POST',
            data: data,
            cache: false,
            dataType: 'json',
            processData: false, // Don't process the files
            contentType: false, // Set content type to false as $ will tell the server its a query string request
            success: function(data, textStatus, jqXHR) {
              publicationUpload.initFileUpload(data);
            },
            error: function(jqXHR, textStatus, errorThrown) {
              var result = $.parseJSON(jqXHR.responseText),
                error_keys = Object.keys(result.errors),
                error_messages = [];

              $.each(result.errors, function(value) {
                error_messages.push(result.errors[value]);
              });

              $('.help-block').remove();
              $('.form-group').removeClass('has-error');

              for (var i = error_keys.length - 1; i >= 0; i--) {

                $('input[name=' + error_keys[i] + '], textarea[name=' + error_keys[i] + ']').closest('.form-group').addClass('has-error').append(
                '<span id="help' + error_keys[i] + '" class="help-block">' + error_messages[i] + '</span>');

              };
            }

          });

          return false;
        }
      });
    }
  };

  var initTour = function () {
    var tour = new Shepherd.Tour({
      defaults: {
        classes: 'shepherd-theme-arrows',
        // scrollTo: true
      },
    });

    /* var scrollHandler = (el) => {
          //console.log(el);
          var winHeight = $(window).height();
          // Target vertical middle scroll position
          var targetPos = ($(el).offset().top + $(el).position().top) - (winHeight / 2);
          //console.log(winHeight);
          //console.log(targetPos);
          $("html, body").animate({ scrollTop: targetPos }, 1000);
      };*/

    tour.addStep('example', {
      title: 'Welcome',
      text:
        'This is the centre for your Irish research. Here you can create collections containing your information and share them with the community if you wish. You have now become a member of our exclusive library, and you have access to our research experts whenever you need them. You will also be able to follow relevant topics, people, counties and collections.',
      attachTo: '.explore bottom',
      advanceOn: '.docs-link click',
    });

    tour.addStep('example', {
      title: 'Profile',
      text:
        'Here you will find everything related to your profile. You can also manange your privacy, update your subscription, check the status of an order or simply change your account information.',
      attachTo: '.me bottom',
      advanceOn: '.docs-link click',
    });

    tour.addStep('example', {
      title: 'Resources',
      text:
        'You will be able to upload a resource either for your own use or to share with the community, this may be in the form of a PDF file, Image, Youtube or web link.',
      attachTo: '.plus bottom',
      advanceOn: '.docs-link click',
    });

    tour.addStep('example', {
      title: 'Experts',
      text:
        'Here you can ask one of our research experts a question or 5 if you are an annual member. Perhaps you have a question you think only an expert will know the answer to ?',
      attachTo: '.expert bottom',
      advanceOn: '.docs-link click',
    });

    tour.addStep('example', {
      title: 'Research',
      text:
        "Have you hit a brick wall ? or perhaps you just don't have the time or resources to get the research done. This is where you can hire one of our professionals to help you solve the mystery in your family history.",
      attachTo: '.commission bottom',
      advanceOn: '.docs-link click',
    });

    tour.addStep('example', {
      title: 'Topics',
      text:
        'Have you a specific topic you want to know more about or want to focus on ? follow a topic and see what is related to it on the website that may help you with your research.',
      attachTo: '.shepherd-topics top',
      advanceOn: '.docs-link click',
      scrollTo: true,
      /* ,
                 scrollToHandler: scrollHandler*/
    });

    tour.addStep('example', {
      title: 'People',
      text:
        'You can also follow other members of the site and see what they have added to their collections that may interest you.',
      attachTo: '.shepherd-people top',
      advanceOn: '.docs-link click',
      scrollTo: true,
      /* ,
                 scrollToHandler: scrollHandler*/
    });

    tour.addStep('example', {
      title: 'Counties',
      text: 'Here you can follow certain Counties that may be of interest to your research.',
      attachTo: '.shepherd-counties top',
      advanceOn: '.docs-link click',
      scrollTo: true,
      /* ,
                 scrollToHandler: scrollHandler*/
    });

    tour.addStep('example', {
      title: 'Collections',
      text: 'Here you can follow other collections that have been created by members of the site.',
      attachTo: '.shepherd-collections top',
      advanceOn: '.docs-link click',
      scrollTo: true /*            scrollToHandler: scrollHandler,*/,
      buttons: {
        text: 'finish',
        action: tour.next,
      },
    });

    tour.start();

    $(document).mouseup(function(e) {
      var container = $('.shepherd-content');

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        tour.cancel();
        localStorage.setItem('welcome', true);
      }
    });
  };

  var initProfileTour = function() {
    var tour = new Shepherd.Tour({
      defaults: {
        classes: 'shepherd-theme-arrows',
        // scrollTo: true
      },
    });

    /* var scrollHandler = (el) => {
          //console.log(el);
          //console.log('here');
          $('.nav-tabs > .active').next('li').find('a').trigger('click');
          //console.log($('.nav-tabs > .active').next('li').find('a'));
      };*/

    tour.addStep('example', {
      title: 'Profile',
      text:
        "Here you can edit your profile and var the community know more about you and the research you're carrying out.",
      attachTo: '.edit-profile bottom',
      advanceOn: '.docs-link click',
    });

    tour.addStep('example', {
      title: 'Profile Photo',
      text: 'Here you can edit your profile photo. It can be useful to put a face to a name.',
      attachTo: '.edit-photo bottom',
      advanceOn: '.docs-link click',
    });

    tour.addStep('example', {
      title: 'Activity',
      text:
        'Here you will find recent comments you have made and recent resources you have uploaded etc.',
      attachTo: '.activity top',
      advanceOn: '.docs-link click',
    });

    tour.addStep('example', {
      title: 'Collections',
      text:
        'This page is home to your collections and are managed and stored here for you to edit existing ones and upload new ones when you wish',
      attachTo: '.collections top',
      advanceOn: '.docs-link click',
      scrollTo: true,
      /* ,
                 scrollToHandler: scrollHandler*/
    });

    tour.addStep('example', {
      title: 'Following',
      text:
        'Access the Topics, People, Counties, and Collections that you have followed here and see what the latest updates related to them are.',
      attachTo: '.following top',
      advanceOn: '.docs-link click',
      scrollTo: true,
      /* ,
                 scrollToHandler: scrollHandler*/
    });

    tour.addStep('example', {
      title: 'Followers',
      text:
        'These are the people who are following you. Follow them back if you would like to connect with them!',
      attachTo: '.followers top',
      advanceOn: '.docs-link click',
      scrollTo: true /*            scrollToHandler: scrollHandler,*/,
      buttons: {
        text: 'finish',
        action: tour.next,
      },
    });

    tour.start();

    $(document).mouseup(function(e) {
      var container = $('.shepherd-content');

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        tour.cancel();
        localStorage.setItem('profile', true);
      }
    });
  };

  if (window.location.href.indexOf('profile') > -1 && localStorage.getItem('profile') === null) {
    initProfileTour();
  }

  $('.take-tour').click(function() {
    if (window.location.href.indexOf('explore') > -1) {
      initTour();
    } else if (window.location.href.indexOf('profile') > -1) {
      initProfileTour();
    }
  });

  // Onboarding Tour shepherd
  if (window.location.href.indexOf('explore') > -1 && localStorage.getItem('welcome') === null) {
    initTour();
  }

  var subscriptionForm = {
    init: function() {
      var duration = $('select#duration'),
        duration_in_months_holder = $('#duration_in_months_holder');

      duration.bind({
        change: function() {
          // console.log($(this).val());
        },
      });
    },
  };

  var slugger = {
  
    init: function() {

      $(document).on('keyup keypress blur change', '[data-slugify]', function () {

        if($(this).data('slugify')) {
          var target = $(this).data('slugify');
          $(target).val(slugger.slugify($(this).val()));
        }

      });

    },
    
    slugify: function(str) {
      
      var from = "ąàáäâãåæćęęèéëêìíïîłńòóöôõøśùúüûñçżź",
          to = "aaaaaaaaceeeeeeiiiilnoooooosuuuunczz",
          regex = new RegExp('[' + from.replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1') + ']', 'g');

      if (str === null) return '';
      
      str = String(str).toLowerCase().replace(regex, function (c) {
        return to.charAt(from.indexOf(c)) || '-';
      });
      
      return str.replace(/[^\w\s-]/g, '').replace(/([A-Z])/g, '-$1').replace(/[-_\s]+/g, '-').replace(/^-+/, '').replace(/-+$/, '').toLowerCase();

    }
    
  }


  // Listen for the $ ready event on the document
  $(function() {
    navigation.init();
    ajaxActions.init();
    modalActions.init();
    adjustJumbotron();
    collectionPrivacy.init();
    modalSafariFix(); // A hacky fix for Safari modal display
    initTypekit();
    bindShowArticleParents();
    initCheckout.init();
    initStripe.init();
    publicationUpload.init();
    initFilter.init();
    // Admin functions
    adminFunctions();
    slugger.init();
    // subscriptionForm.init();
  });

  // Listen for window resize
  $(window).resize(function() {
    adjustJumbotron();
    adjustArticles();
  });


}));
